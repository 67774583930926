import { Injectable, Inject } from "@angular/core";
import { Observable, of } from "rxjs";
import { tap, share, finalize, mergeMap, map } from "rxjs/operators";
import { APP_CONFIG, AppConfig, PersistentStore } from "app/core";
import { ConversationId } from "./conversation-id.model";
import { ConversationApiClient } from "./conversation.apiclient";
import { CONVERSATION_ID_PERSISTENT_STORE } from "./di-tokens";
import { OutgoingMessageFactory } from "app/chat/outgoing";

/** A store for the token used to access a chat conversation. */
@Injectable({ providedIn: "root" })
export class ConversationIdStore {
  /** Only available if the creation of a new conversation is being done */
  private futureId?: Observable<ConversationId>;

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    private apiClient: ConversationApiClient,
    private outgoingMessageFactory: OutgoingMessageFactory,
    @Inject(CONVERSATION_ID_PERSISTENT_STORE)
    private persistentStore: PersistentStore<ConversationId>
  ) {
    if (config.hasResetConversationOnReload) {
      persistentStore.clear();
    }
  }

  private createNew(): Observable<ConversationId> {
    if (!this.futureId) {
      this.persistentStore.clear();
      let conversationId: ConversationId;
      this.futureId = this.apiClient
        .create()
        .pipe(
          tap((data: ConversationId) => {
            this.persistentStore.set(data);
            conversationId = data;
          }),
          // send ping once the conversation is created
          mergeMap(() =>
            this.apiClient.postMessage(
              conversationId,
              this.outgoingMessageFactory.makeInitializationMessage()
            )
          ),
          map(() => conversationId),
          finalize(() => (this.futureId = undefined))
        )
        .pipe(share());
    }
    return this.futureId;
  }

  /** @return The current conversation ID, or the ID of a new conversation, if none exists */
  get(): Observable<ConversationId> {
    const storedId = this.persistentStore.get();
    return storedId === null ? this.createNew() : of(storedId);
  }

  /** Force the currently stored token cleaning */
  public invalidate(): void {
    this.persistentStore.clear();
  }
}
