import { InjectionToken } from "@angular/core";
import { PersistedChatOpenState } from "./open.store";
import { LocalStorageStore, PersistentStore } from "app/core";

/** Dependency injection tokens */
export const CHAT_OPEN_PERSISTENT_STORE = new InjectionToken<
  PersistentStore<PersistedChatOpenState>
>("CHAT_OPEN_PERSISTENT_STORE", {
  factory: () =>
    new LocalStorageStore<PersistedChatOpenState>(
      window.localStorage,
      "botopenstate",
      ["open"],
      3600000
    ),
});

export const CHAT_CLOSING_DELAY = new InjectionToken<number>("CHAT_CLOSING_DELAY", {
  factory: () => 2000,
});
