import { Inject, Injectable } from "@angular/core";
import { APP_CONFIG } from "../config";
import { AppConfig } from "../config/config.model";
import { Point } from "./point.model";

/** A service used to interact with the parent iframe */
@Injectable({ providedIn: "root" })
// TODO refactor when refactoring loader
export class AppParentService {
  constructor(@Inject(APP_CONFIG) private config: AppConfig) {}

  /** Send a message to the parent container */
  postMessageToParent(eventId: string, value: any = null) {
    parent.postMessage({ eventId, value }, "*");
  }

  /** Force the display of the bot in the parent container */
  public setBotVisible(visible: boolean): void {
    this.postMessageToParent("botToggled", visible);
  }

  /** Remove this bot from the parent container */
  public removeBot(): void {
    this.postMessageToParent("remove");
  }

  /** Close the parent container */
  public closeParent(): void {
    this.postMessageToParent("closeIframe");
  }

  /** Requests the parent to display the privacy center to the user */
  public showPrivacyCenter(): void {
    this.postMessageToParent("showPrivacyCenter");
  }

  /** Request the parent container to display a popup with an image denoted by the passed URL */
  public showImagePopup(url: string): void {
    this.postMessageToParent("image", url);
  }

  /** Request the parent container to change iFrame dimensions */
  public updateHostDimensions(): void {
    if (this.config.hostDimensions) {
      this.postMessageToParent("updateHostDimensions", this.config.hostDimensions);
    }
  }

  public setUpDragAndDrop(): void {
    this.postMessageToParent("setUpDragAndDrop");
  }
}
