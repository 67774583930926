//The data model of each message handled by the business layers of this application.

import { SatisfactionEmoji, Suggestion } from "../incoming/connector";

export { SatisfactionEmoji, Suggestion } from "../incoming/connector";

/**
 * none: no relation
 * introducer: the message is a text message that introduces an interaction message
 * introduced: the message is an interaction message that is introduced by a text message
 */
export type MessageRelationType = "none" | "introducer" | "introduced";

/**
 * Only set (not default) if the message is a text or image message that is part of
 * a sequence of consecutive text or image messages.
 * start: the message is the first of the sequence.
 * middle: the message is not the first or the last in the sequence.
 * end: the message is the last of the sequence.
 * default: the message is not a text or image message
 */
export type MessagePositionType = "start" | "middle" | "end" | "default";

const generateMessageId = (() => {
  let messagesCounter = 0;
  return () => {
    messagesCounter++;
    return messagesCounter;
  };
})();

/** Metadata common to all the messages. */
export class MessageMetadata {
  /** The unique identifier for the message */
  public readonly id = generateMessageId() + "";
  /** The unique identifier for the message, suitable to be used in HTML templates */
  public readonly htmlId = "message-" + this.id;

  /**
   * @param animationUrl URL of the animation to be played when this message is displayed
   * @param relation The type of relation a message can have with other messages
   * @param position The position of the message in a sequence of consecutive text or image messages.
   * @param scrollOn Whether to scroll on this message or not.
   */
  public constructor(
    public readonly animationUrl: string | undefined,
    public relation: MessageRelationType,
    public position: MessagePositionType,
    public scrollOn: boolean
  ) {}

  /** @return A newly built metadata object with default values */
  static makeDefault(): MessageMetadata {
    return new MessageMetadata(undefined, "none", "default", true);
  }
}

/** A text message issued by the user that can be displayed to the user in the conversation thread. */
export class UserTextMessage {
  constructor(
    private readonly _text: string,
    private readonly _userWritten: boolean,
    private readonly _color: string,
    private readonly _metadata: MessageMetadata
  ) {}

  /** @return A text message from a user, not resulting from an interaction */
  static makeTextMessage(text: string) {
    return new UserTextMessage(text, true, "", new MessageMetadata(undefined, "none", "end", true));
  }
  /** @return A text message from a user, resulting from an interaction */
  static makeInteractionResultMessage(text: string, color: string) {
    return new UserTextMessage(
      text,
      false,
      color,
      new MessageMetadata(undefined, "none", "end", true)
    );
  }

  /** @return The displayable text */
  get text() {
    return this._text;
  }
  /** @return Whether the text message was actually written by the user, or is the result of an interaction (suggestion, ...) */
  get userWritten() {
    return this._userWritten;
  }
  /** @return The color for the view of the message. Empty if not available */
  get color() {
    return this._color;
  }
  /** @return Metadata for the message */
  get metadata() {
    return this._metadata;
  }
}

/** A text message issued by the chatbot that can be displayed to the user in the conversation thread. */
export class BotTextMessage {
  constructor(
    private readonly _text: string,
    private readonly _metadata: MessageMetadata
  ) {}

  /** @return A text message from the bot */
  static makeMessage(text: string, metadata: MessageMetadata) {
    return new BotTextMessage(text, metadata);
  }
  /** @return A text message from the bot, that introduces other messages */
  static makeIntroducerMessage(text: string) {
    return new BotTextMessage(text, new MessageMetadata(undefined, "introducer", "end", true));
  }

  /** @return The displayable text */
  get text() {
    return this._text;
  }
  /** @return Metadata for the message */
  get metadata() {
    return this._metadata;
  }
}

/** An image message that can be displayed to the user in the conversation thread. */
export class ImageMessage {
  /**
   * @param url The URL of the image to be displayed
   * @param altText The description of the image, for accessibility purpose
   * @param metadata Metadata for the message
   */
  constructor(
    public readonly url: string,
    public readonly altText: string,
    public readonly metadata: MessageMetadata
  ) {}
}

/** An interaction message, allowing a user to select a suggestion among several ones. */
export class SuggestionsMessage {
  /**
   * @param suggestions The available suggestions
   * @param metadata Metadata for the message
   */
  constructor(
    public readonly suggestions: readonly Suggestion[],
    public readonly metadata: MessageMetadata
  ) {}
}

/** An action that opens a browser tab */
export class UrlRedirect {
  /**
   * @param text The differents slides of carousel
   * @param externalUrl  URL of the page to be opened in a new browser tab
   */
  constructor(
    public readonly text: string,
    public readonly externalUrl: string
  ) {}
}

/** One slide of carousel */
export class CarouselItem {
  constructor(
    public readonly imageUrl: string,
    public readonly imageAlt: string,
    public readonly title: string,
    public readonly description?: string,
    public readonly action?: Suggestion | UrlRedirect
  ) {}
}

/** An interaction message, allowing a user to see different slides with internal or external redirection. */
export class CarouselMessage {
  /**
   * @param items The differents slides of carousel
   * @param metadata Metadata for the message
   */
  constructor(
    public readonly items: readonly CarouselItem[],
    public readonly metadata: MessageMetadata
  ) {}
}

/** An interaction message, allowing a user to evaluate his satisfaction through a level */
export class SatisfactionLevelMessage {
  /**
   * @param suggestions The available suggestions, corresponding to each evaluation level and
   *                    hence, stars number
   * @param metadata Metadata for the message
   */
  constructor(
    public readonly suggestions: readonly Suggestion[],
    public readonly metadata: MessageMetadata
  ) {}
}

/** An interaction message, allowing a user to evaluate his satisfaction through emojis. */
export class SatisfactionEmojisMessage {
  /**
   * @param intention The intention for this evaluation.
   * @param emojis The available emojis
   * @param metadata Metadata for the message
   */
  constructor(
    public readonly intention: string,
    public readonly emojis: readonly SatisfactionEmoji[],
    public readonly metadata: MessageMetadata
  ) {}
}

/** An interaction message, allowing a user to evaluate his satisfaction through a free comment. */
export class SatisfactionCommentMessage {
  /**
   * @param text The displayable text introducing the evaluation.
   * @param intention The intention for this evaluation.
   * @param metadata Metadata for the message
   */
  constructor(
    public readonly text: string,
    public readonly intention: string,
    public readonly metadata: MessageMetadata
  ) {}
}

/** Questionnaire to be used by a user to evaluate a chatbot answer. */
export interface EvaluationQuestionnaire {
  /** The ID of the conversation step */
  stepId: number;
  /** Metadata related to the chatbot tree */
  nodeName: string;
  /** Text to introduce the evaluation interaction elements */
  questionText: string;
  /** Text to be displayed to the user, if the user evaluation is positive. No other interaction is requested after this message is displayed. */
  textOnPositiveEvaluation: string;
  /** Text to be displayed to the user, if the user evaluation is negative, to introduce the options to allow the user to provide complementary information on his evaluation */
  textOnNegativeEvaluation: string;
  /** Possible options for the user to provide complementary information on his negative evaluation */
  furtherEvaluationOptions: {
    /** Label for the option, to be displayed to the user */
    label: string;
    /** Key for identifying the option */
    key: string;
  }[];
}

export type ConversationMessage =
  | UserTextMessage
  | BotTextMessage
  | ImageMessage
  | SuggestionsMessage
  | SatisfactionLevelMessage
  | SatisfactionEmojisMessage
  | SatisfactionCommentMessage
  | CarouselMessage;

export type HistoryMessage = UserTextMessage | BotTextMessage | ImageMessage | CarouselMessage;
export type StaticMessage = UserTextMessage | BotTextMessage | ImageMessage | CarouselMessage;

/**
 * @returns Whether the passed message is an interaction one.
 */
export function isInteraction(message: ConversationMessage): boolean {
  return (
    message instanceof SuggestionsMessage ||
    message instanceof SatisfactionLevelMessage ||
    message instanceof SatisfactionEmojisMessage ||
    message instanceof SatisfactionCommentMessage ||
    message instanceof CarouselMessage
  );
}

/**
 * The conversation block the user interacts with.
 * Corresponds to the last received conversation block, which is
 * always the block with which the user interacts.
 * Past conversation blocks are never represented by this class (see history services for that).
 */
export class CurrentConversationBlock {
  public readonly htmlId;
  /**
   * @param id The unique identifier of the block
   * @param temporary Whether this block is temporary, ie, displayed only when waiting for
   *                  the server response to a user message
   * @param messages The messages in the block
   * @param evaluationQuestionnaire The questionnaire to be used when this conversation step
   *                                needs user evaluation.
   */
  constructor(
    public readonly id: string,
    public readonly temporary = false,
    public readonly messages: Array<ConversationMessage>,
    public readonly evaluationQuestionnaire: EvaluationQuestionnaire | null | undefined
  ) {
    this.htmlId = "block-" + this.id;
  }

  /** @return Whether the user is expected to write a text message after reading this block */
  expectsUserText(): boolean {
    return (
      !this.temporary &&
      this.messages.length > 0 &&
      !isInteraction(this.messages[this.messages.length - 1])
    );
  }
}
