import { InjectionToken, inject } from "@angular/core";
import { PersistedUser, UserRights } from "./user.model";
import { APP_CONFIG, AppHostService, LocalStorageStore, PersistentStore } from "app/core";
import { UserStore } from "./user.store";
import { UserStoreIdrhImpl } from "./user.idrh.store";
import { UserStoreDefaultImpl } from "./user.default.store";

/** Dependency injection tokens */
export const USER_RIGHTS_PERSISTENT_STORE = new InjectionToken<PersistentStore<UserRights>>(
  "USER_RIGHTS_PERSISTENT_STORE",
  {
    factory: () =>
      new LocalStorageStore<UserRights>(window.localStorage, "UserRights", ["canWrite"], 0),
  }
);
export const USER_STORE = new InjectionToken<UserStore>("USER_STORE", {
  factory: () => {
    const config = inject(APP_CONFIG);
    if (config.idrhSupport) {
      const userPersistentStore = new LocalStorageStore<PersistedUser>(
        window.localStorage,
        "",
        ["idrh", "IDRH", "user-jwt"],
        0
      );
      return new UserStoreIdrhImpl(
        config.idrhSupport.required,
        inject(AppHostService),
        userPersistentStore,
        config
      );
    } else {
      return new UserStoreDefaultImpl();
    }
  },
});
