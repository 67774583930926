import { Inject, Injectable } from "@angular/core";
import { IncomingConversationBlock, BotIncomingMessage, UserIncomingMessage } from "../connector";
import {
  BotTextMessage,
  CarouselMessage,
  ConversationMessage,
  CurrentConversationBlock,
  EvaluationQuestionnaire,
  ImageMessage,
} from "../../common/message.model";
import { INCOMING_BOT_MESSAGE_CONVERTERS, INCOMING_USER_MESSAGE_CONVERTERS } from "./di-tokens";
import {
  BotIncomingMessageConverter,
  UserIncomingMessageConverter,
} from "./message-converter.model";
import { AppConfig, APP_CONFIG } from "app/core";

/**
 * @return An evaluation questionnaire generated from the passed
 *         block, or undefined if no active questionnaire is found
 */
function toEvaluationQuestionnaire(
  block: IncomingConversationBlock
): EvaluationQuestionnaire | undefined {
  if (block.bot_message?.evaluation_answers?.is_evaluation_active !== true) {
    return undefined;
  }
  const data = block.bot_message.evaluation_answers;
  return {
    stepId: block.id,
    nodeName: block.bot_message.context.state_id,
    questionText: data.question_text,
    textOnPositiveEvaluation: data.text_after_positive_answer,
    textOnNegativeEvaluation: data.text_after_negative_answer,
    furtherEvaluationOptions: [
      { label: data.negative_evaluation_options.option1, key: "reason_1" },
      { label: data.negative_evaluation_options.option2, key: "reason_2" },
      { label: data.negative_evaluation_options.option3, key: "reason_3" },
    ],
  };
}

/**
 * Marks the position of text and image messages into a sequence.
 * A sequence is defined as a list of consecutive text or image messages.
 * A message introducing another one ends a sequence.
 */
function markPositionInSequence(messages: Array<ConversationMessage>): void {
  messages
    .reduce(
      (accumulator, current) => {
        if (
          current instanceof BotTextMessage ||
          current instanceof ImageMessage ||
          current instanceof CarouselMessage
        ) {
          current.metadata.position = "middle";
          accumulator[accumulator.length - 1].push(current);
        } else {
          accumulator.push([]);
        }
        return accumulator;
      },
      [new Array<BotTextMessage | ImageMessage | CarouselMessage>()]
    )
    .filter((sequence) => sequence.length > 0)
    .forEach((sequence) => {
      sequence[0].metadata.position = "start";
      sequence[sequence.length - 1].metadata.position = "end";
    });
}

/**
 * A converter of the messages from the backend server to business layer messages.
 * This converter is an abstraction layer that avoids leaking the backend server
 * messages model into the UI components and the business services.
 * It enriches the business layer messages metadata, to avoid complex computings in UI
 * components or business layer services.
 * If you need to support a new kind of messages, create a converter for it, and
 * reference it in the INCOMING_USER_MESSAGE_CONVERTERS array or in the INCOMING_BOT_MESSAGE_CONVERTERS.
 * Beware of the order of the converters!
 */
@Injectable({ providedIn: "root" })
export class IncomingConverter {
  /** Counter to compute the ID of each generated block. */
  private blocksCounter = 0;

  constructor(
    @Inject(INCOMING_USER_MESSAGE_CONVERTERS)
    private incomingUserMessageConverters: Array<UserIncomingMessageConverter>,
    @Inject(INCOMING_BOT_MESSAGE_CONVERTERS)
    private incomingBotMessageConverters: Array<BotIncomingMessageConverter>,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {}

  private firstMatchingUserMessageConverter(incomingMessage: UserIncomingMessage) {
    for (let converter of this.incomingUserMessageConverters) {
      if (converter.supports(incomingMessage)) {
        return converter;
      }
    }
    return undefined;
  }
  private firstMatchingBotMessageConverter(incomingMessage: BotIncomingMessage) {
    for (let converter of this.incomingBotMessageConverters) {
      if (converter.supports(incomingMessage)) {
        return converter;
      }
    }
    return undefined;
  }

  private convertBotMessage(incomingMessage: BotIncomingMessage) {
    return this.firstMatchingBotMessageConverter(incomingMessage)?.convert(incomingMessage);
  }

  /**
   * @return The current conversation block corresponding to the passed backend block.
   *         All incoming messages are filtered or consolidated to simplify their usage
   *         in the application business layer.
   */
  toCurrentBlock(block: IncomingConversationBlock): CurrentConversationBlock {
    const userMessage = this.firstMatchingUserMessageConverter(block.user_message)?.convert(
      block.user_message
    );

    const botMessages: Array<ConversationMessage> = block.bot_message.answers
      .flatMap((incomingMessage) => this.convertBotMessage(incomingMessage))
      .filter((message) => message !== undefined)
      .map((message) => message as ConversationMessage);
    markPositionInSequence(botMessages);

    // If personal data message is displayed, indicate to not scroll on first messages.
    if (this.config.personalDataMessage && this.blocksCounter === 0) {
      botMessages.forEach((botMessage) => {
        botMessage.metadata.scrollOn = false;
      });
    }

    this.blocksCounter++;
    return new CurrentConversationBlock(
      this.blocksCounter + "",
      false,
      userMessage ? [userMessage, ...botMessages] : botMessages,
      toEvaluationQuestionnaire(block)
    );
  }
}
