import { Injectable, Inject } from "@angular/core";
import { Subject } from "rxjs";
import { APP_CONFIG, AppConfig } from "./config";
import { ParentLocationUrlValue } from "./parent-location-url.value";
import { DeviceUtil } from "./util";

export type DeviceType = "mobile" | "wide";

/**
 * A service used to store data related to the device and the browser tab.
 */
@Injectable({ providedIn: "root" })
export class AppHostService {
  private _isSmallScreen = false;
  /** Subject to notify changes of the user consent on the privacy terms  */
  private _hasConsentOnPrivacyTerms = false;

  public focusOnUserInput$ = new Subject<number | null>();

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    private parentLocationUrl: ParentLocationUrlValue,
    private device: DeviceUtil
  ) {
    // if consent is not needed, we consider the user gave his consent
    this._hasConsentOnPrivacyTerms = !config.needsConsentOnPrivacyTerms;

    // listen to main window events and dispatch them
    if (window.addEventListener) {
      window.addEventListener("message", this.onWindowEvent.bind(this));
    } else {
      (<any>window).attachEvent("onmessage", this.onWindowEvent.bind(this));
    }
  }

  //TODO : refactor isSmallScreen when using boostrap
  private onWindowEvent(event: MessageEvent): void {
    // check on screen size
    if (event.data.eventId && event.data.eventId === "isSmallScreen") {
      this._isSmallScreen = event.data.isSmallScreen;
    }
    // check on privacy
    if (event.data?.hasOwnProperty("tcPrivacy")) {
      this._hasConsentOnPrivacyTerms = event.data.tcPrivacy;
    }
    // check on closing image previously opened
    if (event.data.eventId && event.data.eventId === "focusAfterImageClosed") {
      this.focusOnUserInput$.next(Date.now());
    }
  }

  /** @return The current browser tab URL */
  get currentUrl(): string {
    return this.parentLocationUrl.value;
  }

  /** @return Whether the application is being executed on a small screen */
  get isSmallScreen(): boolean {
    return this._isSmallScreen;
  }

  /** @return The device type on which the application is running (mobile or wide) */
  get deviceType(): DeviceType {
    return this.isMobile ? "mobile" : "wide";
  }

  /** @return Whether the application is being executed on mobile */
  get isMobile(): boolean {
    return this.device.isMobile;
  }

  /** @return Whether the current bot is a test one */
  get isTestBot(): boolean {
    return !!(window as any).isSandbox;
  }

  /** @return Whether the user has given his consent on the privacy terms */
  get hasConsentOnPrivacyTerms(): boolean {
    return this._hasConsentOnPrivacyTerms;
  }
}
