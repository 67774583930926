import { Inject, Injectable, Signal, WritableSignal, signal } from "@angular/core";
import { APP_CONFIG, AppConfig, PersistentStore } from "app/core";
import { UserRights } from "./user.model";
import { USER_RIGHTS_PERSISTENT_STORE } from "./di-tokens";

/** A store to access rights for the current user. */
@Injectable({ providedIn: "root" })
export class UserRightsStore {
  private readonly _canWrite: WritableSignal<boolean>;

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    @Inject(USER_RIGHTS_PERSISTENT_STORE)
    private persistentStore: PersistentStore<UserRights>
  ) {
    if (config.hasResetConversationOnReload) {
      persistentStore.clear();
    }
    const persisted = this.persistentStore.get() ?? {
      canWrite: "true" /* true by default */,
    };
    this._canWrite = signal(persisted.canWrite === "true");
  }

  /** @return A signal on the user right to write */
  public get canWriteSignal(): Signal<boolean> {
    return this._canWrite;
  }

  /** @return Whether the user can currently write */
  public get canWrite(): boolean {
    return this._canWrite();
  }

  /** Sets whether the user can write */
  public set canWrite(newValue: boolean) {
    const newValueAsString = newValue + "";
    let data = this.persistentStore.get() ?? { canWrite: newValueAsString };
    data.canWrite = newValueAsString;
    this.persistentStore.set(data);
    this._canWrite.set(newValue);
  }
}
