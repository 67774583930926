import { PersistentStore } from "./persistent-store.util";

const timestampKey = "timestamp";

/** A persistent store on the local storage */
export class LocalStorageStore<Model> implements PersistentStore<Model> {
  /**
   * The constructor of the class
   * @param modelKeys The array of the keys to be stored from the model object
   * @param timeToLiveInMs Validity period for stored data, in milliseconds.
   *                       Use 0 to deactivate checkings on duration.
   */
  constructor(
    private localStorageService: Storage,
    private storeId: string,
    private modelKeys: Array<string>,
    private timeToLiveInMs = 0
  ) {}

  private absoluteKey(key: string): string {
    if (this.storeId) {
      return this.storeId + "_" + key;
    }
    return key;
  }

  /** @return Whether the stored data is fresh */
  private isFresh(): boolean {
    // check on timestamp is deactivated
    if (this.timeToLiveInMs === 0) {
      return true;
    }
    const timestampAsString = this.localStorageService.getItem(this.absoluteKey(timestampKey));
    if (!timestampAsString) {
      return false;
    }
    const timestamp = parseInt(timestampAsString, 10);
    if (isNaN(timestamp)) {
      return false;
    }
    const outdatedTimestamp = Date.now() - this.timeToLiveInMs;
    return timestamp > outdatedTimestamp;
  }

  /**
   * @return The stored data, or null if the data is not fresh or has never been set
   */
  public get(): Model | null {
    if (!this.isFresh()) {
      return null;
    }
    let result = {} as Model;
    let noKeysStored = true;
    for (const key of this.modelKeys) {
      let storedValue;
      try {
        storedValue = this.localStorageService.getItem(this.absoluteKey(key));
      } catch (error) {
        console.error("Local storage is not available: getItem");
        storedValue = null;
      }
      if (storedValue !== undefined && storedValue !== null) {
        noKeysStored = false;
      }
      (result as any)[key] = storedValue ?? "";
    }
    return noKeysStored ? null : result;
  }

  public set(data: Model): void {
    try {
      this.localStorageService.setItem(this.absoluteKey(timestampKey), Date.now().toString());
      for (const key of this.modelKeys) {
        this.localStorageService.setItem(this.absoluteKey(key), (data as any)[key]);
      }
    } catch (error) {
      console.error("Local storage is not available: setItem");
    }
  }

  public clear(): void {
    try {
      this.localStorageService.removeItem(this.absoluteKey(timestampKey));
      for (const key of this.modelKeys) {
        this.localStorageService.removeItem(this.absoluteKey(key));
      }
    } catch (error) {
      console.error("Local storage is not available: removeItem");
    }
  }
}
