import { Injectable, Inject } from "@angular/core";
import { BehaviorSubject, Observable, Subject, delay, filter, map } from "rxjs";
import { PersistentStore, APP_CONFIG, AppConfig, AppHostService } from "app/core";
import { CHAT_CLOSING_DELAY, CHAT_OPEN_PERSISTENT_STORE } from "./di-tokens";

/** Enables to store the chat opening status */
export interface PersistedChatOpenState {
  open: string;
}

/**
 * @return Whether the application should open the chatbot by default, according to the
 * "whereToBeOpenByDefault" configuration key
 */
function shouldOpenByDefault(config: AppConfig, hostService: AppHostService): boolean {
  return !!(
    (config.whereToBeOpenByDefault &&
      hostService.currentUrl.includes(config.whereToBeOpenByDefault)) ||
    hostService.currentUrl.includes("localhost") ||
    hostService.currentUrl.includes("ui.chatbotrh")
  );
}

/**
 * A store for the chat open state.
 * When the chat is open, it is active, otherwise, no message can be
 * emitted or received (once the current polling sequence is over).
 *
 * The chat should be opened at startup if:
 * - The configuration forces the opening at all times (config.open = true)
 * - The configuration forces the opening for the current URL (whereToBeOpenByDefault)
 * - The bot was opened since less than one hour in the current tab or in another one (as defined in local storage)
 *
 * If the bot is opened at startup, the local storage is not updated: if the opening should be
 * forced for the current URL, the other tabs with different URLs should not be impacted.
 *
 */
@Injectable({ providedIn: "root" })
export class ChatOpenStore {
  private value$: BehaviorSubject<boolean>;
  private delayedValue$ = new Subject<boolean>();
  private _closingByUserBlocked: boolean;

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    hostService: AppHostService,
    @Inject(CHAT_OPEN_PERSISTENT_STORE)
    private persistentStore: PersistentStore<PersistedChatOpenState>,
    @Inject(CHAT_CLOSING_DELAY)
    chatClosingDelay: number
  ) {
    this._closingByUserBlocked = !!config.opened;
    this.value$ = new BehaviorSubject<boolean>(
      config.opened ||
        shouldOpenByDefault(config, hostService) ||
        this.persistentStore.get()?.open === "true"
    );
    // listen to close events in delayedValue$, delay those events, and then close
    this.delayedValue$
      .pipe(
        filter((isOpening: boolean) => !isOpening),
        delay(chatClosingDelay)
      )
      .subscribe(() => this.close());
  }

  /** @return Whether the closing of the chat bot is blocked for the user */
  get closingByUserBlocked(): boolean {
    return this._closingByUserBlocked;
  }

  /** @return The current open state */
  get isOpen(): boolean {
    return this.value$.getValue();
  }

  /**
   * @return An observable to the events of the opening and closing of the chatbot.
   * The event contains the open state: a boolean stating if the chatbot is open or not
   */
  get isOpen$(): Observable<boolean> {
    return this.value$.asObservable();
  }

  /** @return An observable to the events of the opening of the chatbot. The event contains no value. */
  get isOpening$(): Observable<void> {
    return this.value$.pipe(
      filter((val: boolean) => val === true),
      map(() => {
        return;
      })
    );
  }

  /** @return An observable to the events of the closing of the chatbot. The event contains no value. */
  get isClosing$(): Observable<void> {
    return this.value$.pipe(
      filter((val: boolean) => val === false),
      map(() => {
        return;
      })
    );
  }

  private setOpen(newValue: boolean) {
    this.persistentStore.set({ open: newValue + "" });
    this.value$.next(newValue);
  }

  /** Toggles the opening state of the chatbot. The new state is persisted. */
  open(): void {
    this.setOpen(true);
  }

  /** Closes the chatbot. The new state is persisted. */
  close(): void {
    this.setOpen(false);
  }

  /** Closes the chatbot, with a delay. The new state is persisted. */
  closeWithDelay(): void {
    this.delayedValue$.next(false);
  }
}
