import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AVAILABILITY_STORE, AvailabilityState, AvailabilityStore } from "./availability";
import { ChatLifecycleService } from "app/chat";
import { AppParentService } from "app/core";

/** A service used to retrieve and interact with all the states of the application */
@Injectable({ providedIn: "root" })
export class AppStateService {
  constructor(
    @Inject(AVAILABILITY_STORE) private availabilityStore: AvailabilityStore,
    private chatLifecycleService: ChatLifecycleService,
    private parentService: AppParentService
  ) {}

  /** Initializes all the states in the application, for the states that are required */
  public initialize(): void {
    this.availabilityStore.initialize();
    this.chatLifecycleService.initialize();
    this.parentService.updateHostDimensions();
    this.parentService.setUpDragAndDrop();
  }

  /** @return Whether the application is available for the current user */
  get available$(): Observable<AvailabilityState> {
    return this.availabilityStore.available$;
  }
}
