import { InjectionToken, inject } from "@angular/core";
import { APP_CONFIG, LocalStorageStore } from "app/core";
import { PersistedConversationPrefs } from "./prefs.model";

export const CONVERSATION_PREFS_PERSISTENT_STORE = new InjectionToken<
  LocalStorageStore<PersistedConversationPrefs>
>("CONVERSATION_PREFS_PERSISTENT_STORE", {
  factory: () => {
    const config = inject(APP_CONFIG);
    return new LocalStorageStore<PersistedConversationPrefs>(
      window.localStorage,
      config.localStoragePrefix + config.botVersion + "_conversation_prefs",
      ["keepOnServer", "gdprAccepted"],
      0
    );
  },
});
